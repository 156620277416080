import type { DistanceSystem, DistanceUnit } from "$lib/distance_units";
import type { PaceUnit } from "$lib/PaceUnits";
import type { UserSessionSettings } from "$lib/session/session_initializer.svelte";

export const YARD_TO_METER_SCALAR: number = 0.9144;
export const MILE_TO_METERS_SCALAR: number = 1760 /* Yards in a mile */ * YARD_TO_METER_SCALAR;
export const METER_TO_YARD_SCALAR: number = 1.09361;
export const METER_TO_METER_SCALAR: number = 1;
export const METER_TO_MILE_SCALAR: number = 1/1609.34; //0.00062137273665; //0.000621371;
export const METER_TO_KILOMETER_SCALAR: number = 1/1000;
export const KILOMETER_TO_METERS_SCALAR: number = 1000;
export const PAUSE_THRESHOLDS: {duration: number, pace: number} = 
    {
        duration: 10 * 1000, // 10 seconds
        pace: 0.1 // 10% of typical pace
    };       

export const PACE_CATEGORY_NULL: number = -1;

export type TimeComponents = {hours?: number, minutes?: number, seconds?: number};

export class PaceData {

    readonly time_in_millis: number;
    readonly distance_in_meters: number;

    constructor(time_in_millis: number, distance_in_meters: number) {
        this.time_in_millis = time_in_millis;
        this.distance_in_meters = distance_in_meters;
    }

    static build(other: PaceData | undefined): PaceData | undefined {
        
        if (other === undefined) {
            return undefined;
        }

        return new PaceData(other.time_in_millis, other.distance_in_meters);
    }

    private _calculate_as_milliseconds_per_N_units(units: number, unit_transform_scaler: number): number | undefined {
        
        if (this.distance_in_meters === 0) {
            return undefined;
        }

        let pace_ratio: number = this.time_in_millis / this.distance_in_meters;
        let pace_ratio_per_unit = pace_ratio * (1 / unit_transform_scaler);

        let pace_as_milliseconds_per_N_units = pace_ratio_per_unit * units;

        // console.log("------> ", pace_as_milliseconds_per_N_units, this.time_in_millis, this.distance_in_meters);
        return pace_as_milliseconds_per_N_units;
    }

    public calculate_as_milliseconds_per_100_yards(): number | undefined {

        return this._calculate_as_milliseconds_per_N_units(100, METER_TO_YARD_SCALAR);
    }

    public calculate_as_milliseconds_per_100_meters(): number | undefined {

        return this._calculate_as_milliseconds_per_N_units(100, METER_TO_METER_SCALAR);
    }

    public calculate_as_milliseconds_per_100_preferred_units(preferred_unit?: DistanceSystem): number | undefined {
    
        let to_return;
        if (preferred_unit === 'metric') {

            to_return = this.calculate_as_milliseconds_per_100_meters();

        } else {

            to_return = this.calculate_as_milliseconds_per_100_yards();
        }

        return to_return;
    }


    public display_as_minutes_per_100_yards(): string {
        return display_minutes_and_seconds(this.calculate_as_milliseconds_per_100_yards());
    }

    public display_as_minutes_per_100_meters(): string {
        return display_minutes_and_seconds(this.calculate_as_milliseconds_per_100_meters());
    }

    public display_as_minutes_per_1_mile(): string {
        return display_minutes_and_seconds(this._calculate_as_milliseconds_per_N_units(1, METER_TO_MILE_SCALAR));
    }

    public display_as_minutes_per_1_kilometer(): string {
        return display_minutes_and_seconds(this._calculate_as_milliseconds_per_N_units(1, METER_TO_KILOMETER_SCALAR));
    }

    public calculate_as_meters_per_second(): number {

        let total_seconds = this.time_in_millis / 1000;
        let total_meters = this.distance_in_meters;
        let meters_per_second = total_meters / total_seconds;

        return meters_per_second;
    }

    public display_as_meters_per_second(): string {

        let meters_per_second = this.calculate_as_meters_per_second();

        return meters_per_second.toFixed(2);
    }

    public display_as_minutes_per_100_preferred_units(user_settings?: UserSessionSettings): string {

        let to_return;
        if (user_settings?.preferred_measurement_unit === 'metric') {

            to_return = this.display_as_minutes_per_100_meters();

        } else {

            to_return = this.display_as_minutes_per_100_yards();
        }

        return to_return;
    }

    public display_as_minutes_per_distance_units(distance_units: DistanceUnit): string {

        let to_return;
        if (distance_units === 'm') {
            to_return = this.display_as_minutes_per_100_meters();
        } else if (distance_units === 'yds') {
            to_return = this.display_as_minutes_per_100_yards();
        } else if (distance_units === 'km') {
            to_return = this.display_as_minutes_per_1_kilometer();
        } else if (distance_units === 'mi') {
            to_return = this.display_as_minutes_per_1_mile();
        } else {
            to_return = "N/A";
        }

        return to_return;
    }

    public calculate_as_pace_unit(pace_units: PaceUnit): number | undefined {

        let to_return = undefined;
        if (pace_units === 'min/100m') {
            to_return = this.calculate_as_milliseconds_per_100_meters();
        } else if (pace_units === 'min/100yds') {
            to_return = this.calculate_as_milliseconds_per_100_yards()
        } else if (pace_units === 'min/km') {
            to_return = this._calculate_as_milliseconds_per_N_units(1, METER_TO_KILOMETER_SCALAR);
        } else if (pace_units === 'min/mi') {
            to_return = this._calculate_as_milliseconds_per_N_units(1, METER_TO_MILE_SCALAR);
        } else if (pace_units === 'm/s') {
            to_return = this.calculate_as_meters_per_second();
        }

        return to_return;
    }

    public display_as_pace_unit(pace_units: PaceUnit): string {

        let to_return;
        if (pace_units === 'min/100m') {
            to_return = this.display_as_minutes_per_100_meters();
        } else if (pace_units === 'min/100yds') {
            to_return = this.display_as_minutes_per_100_yards();
        } else if (pace_units === 'min/km') {
            to_return = this.display_as_minutes_per_1_kilometer();
        } else if (pace_units === 'min/mi') {
            to_return = this.display_as_minutes_per_1_mile();
        } else if (pace_units === 'm/s') {
            to_return = this.display_as_meters_per_second();
        } else {
            to_return = "N/A";
        }

        return to_return;
    }

    public display_as_distance_units_label(distance_units: DistanceUnit): string {

        let to_return;
        if (distance_units === 'm') {
            to_return = '/100 m';
        } else if (distance_units === 'yds') {
            to_return = '/100 yds';
        } else if (distance_units === 'km') {
            to_return = '/km';
        } else if (distance_units === 'mi') {
            to_return = '/mi';
        } else {
            to_return = "N/A";
        }

        return to_return;    
    }   

    public display_as_pace_unit_label(pace_unit: PaceUnit): string {

        let to_return;
        if (pace_unit === 'min/100m') {
            to_return = '/100 m';
        } else if (pace_unit === 'min/100yds') {
            to_return = '/100 yds';
        } else if (pace_unit === 'min/km') {
            to_return = '/km';
        } else if (pace_unit === 'min/mi') {
            to_return = '/mi';
        } else if (pace_unit === 'm/s') {
            to_return = 'm/s';
        } else {
            to_return = "N/A";
        }

        return to_return;    
    }   
}

export function extract_minutes_and_seconds(total_milliseconds: number | undefined)
    : TimeComponents | undefined {

    if (total_milliseconds === undefined 
        || isNaN(total_milliseconds) 
        || isFinite(total_milliseconds) === false) {
            
        return undefined;
    }

    let total_seconds = total_milliseconds / 1000;
    let minutes = Math.trunc(total_seconds / 60);
    let seconds = Math.round(total_seconds % 60);

    // Rounding could cause seconds to round up to 60
    if (seconds == 60) {
        minutes += 1;
        seconds = 0;
    }

    return {minutes: minutes, seconds: seconds};
}

export function display_minutes_and_seconds(total_milliseconds: number | undefined) {

    let extract = extract_minutes_and_seconds(total_milliseconds);
    if (extract === undefined) {
        return "N/A";
    }

    let to_return;
    if ((extract.seconds ?? 0) < 10) {
        to_return = `${extract.minutes}:0${extract.seconds}`;
    } else {
        to_return = `${extract.minutes}:${extract.seconds}`;
    }

    return to_return;
}